import React from 'react'
import './Register.css'
import fintech from '../../Asists/img/fintechhub.svg'
import foto from '../../Asists/img/register.png'
export default function Register() {
    return (
        <div className='register'>
            <div className="foto">
                <img src={fintech} alt="" />
            </div>
            <div className="box">
                <h1 className="top">
                    to`xtang! juda muhim
                </h1>
                <h1 className='h1_register'>
                    Professional <span>dasturchi</span> bo'lishingiz <br />
                    uchun oxirgi qadam qoldi
                </h1>
                <a href="https://t.me/fintechhubuz">
                    Kanalga a'zo bo'ling
                    <i className="fa-regular fa-hand-pointer"></i>
                </a>
                <p className="p_register">
                    <span>FintechHub</span> dasturlashga ilk qadam shu yerdan boshlanadi!
                </p>
            </div>
            <div className="bottom">
                <img src={foto} alt="" />
            </div>
        </div>
    )
}
